import React from 'react'
import Page from './Page'
import Block from './primitives/Block'
import Box, { BoxProps } from './primitives/Box'
import Stack from './primitives/Stack'

const borderBottomProps: BoxProps<'tr'> = {
  borderTopStyle: 'solid',
  // @ts-ignore this works, not recognized by Box types
  borderTopWidth: 1,
}

type Subprocessor = {
  name: string
  address: {
    line1: string
    line2: string
    country: string
  }
  scope: string
  locations: ('Europe' | 'USA')[]
}

const thirdParties: Subprocessor[] = [
  {
    name: 'Google Cloud Platform / Google Cloud EMEA Limited',
    address: {
      line1: 'Velasco, Clanwilliam Place',
      line2: 'Dublin 2, Dublin',
      country: 'Ireland',
    },
    scope: 'Cloud hosting for applications and databases',
    locations: ['Europe', 'USA'],
  },
  {
    name: 'HubSpot / HubSpot Ireland Ltd',
    address: {
      line1: 'Ground Floor, Two Dockland Central, Guild Street',
      line2: 'Dublin 1, Dublin',
      country: 'Ireland',
    },
    scope: 'Integrated customer support chat',
    locations: ['Europe', 'USA'],
  },
  {
    name: 'Slack / Slack Technologies Limited',
    address: {
      line1: 'One Park Place, 4th Floor, Hatch Street',
      line2: 'Dublin 2, Dublin',
      country: 'Ireland',
    },
    scope: 'Integrated product notifications',
    locations: ['Europe', 'USA'],
  },
  {
    name: 'Amazon Web Services / Amazon Web Services EMEA SARL',
    address: {
      line1: '38 Avenue John F. Kennedy',
      line2: 'L-1855',
      country: 'Luxembourg',
    },
    scope: 'Offsite backups of encrypted customer data',
    locations: ['Europe'],
  },
  {
    name: 'Functional Software Inc / Sentry',
    address: {
      line1: '45 Fremont Street, 8th Floor',
      line2: 'San Francisco, CA 94105',
      country: 'USA',
    },
    scope: 'Application exception management',
    locations: ['USA'],
  },
  {
    name: 'OpenAI, LLC.*',
    address: {
      line1: '3180 18th St',
      line2: 'San Francisco, CA 94110',
      country: 'USA',
    },
    scope: 'Machine Learning Models in AI-assisted features',
    locations: ['USA'],
  },
  {
    name: 'Twilio Ireland Limited / SendGrid',
    address: {
      line1: '3 Dublin Landings, North Wall Quay',
      line2: 'Dublin 1, Dublin',
      country: 'Ireland',
    },
    scope: 'Automatic email services',
    locations: ['USA'],
  },
  {
    name: 'Pylon Labs Inc.',
    address: {
      line1: '188 King St. Unit 502',
      line2: 'San Francisco, CA 94110',
      country: 'USA',
    },
    scope: 'Customer Support',
    locations: ['USA'],
  },
]

const affiliates: Subprocessor[] = [
  {
    name: 'Swarmia Inc. (Affiliate)',
    address: {
      line1: '10090 W. 26th Ave. Suite 100',
      line2: 'Lakewood, CO 80215',
      country: 'USA',
    },
    scope: 'Customer service and support',
    locations: ['USA'],
  },
]

const SubprocessorTable = ({
  subprocessors,
}: {
  subprocessors: Subprocessor[]
}) => (
  <Box.table fontSize={16} width={1}>
    <Box.thead fontWeight={600}>
      {/* @ts-ignore this works, not recognized by Box types */}
      <Box.tr {...borderBottomProps} borderTopWidth={2} textAlign="left">
        <Box.th>Name</Box.th>
        <Box.th>Address</Box.th>
        <Box.th>Scope</Box.th>
        <Box.th>Locations</Box.th>
      </Box.tr>
    </Box.thead>
    <Box.tbody>
      {subprocessors.map(subprocessor => (
        <Box.tr
          {...borderBottomProps}
          lineHeight="140%"
          key={subprocessor.name}
        >
          <Box.td paddingRight={16} paddingY={8} width={0.25}>
            <Box.div>{subprocessor.name}</Box.div>
          </Box.td>
          <Box.td paddingRight={16} paddingY={8} width={0.35}>
            <Box.div>{subprocessor.address.line1}</Box.div>
            <Box.div>{subprocessor.address.line2}</Box.div>
            <Box.div>{subprocessor.address.country}</Box.div>
          </Box.td>
          <Box.td paddingRight={16} paddingY={8} width={0.25}>
            {subprocessor.scope}
          </Box.td>
          <Box.td paddingRight={16} paddingY={8} width={0.1}>
            {subprocessor.locations.join(', ')}
          </Box.td>
        </Box.tr>
      ))}
    </Box.tbody>
  </Box.table>
)

export const Subprocessors = () => (
  <Page>
    <Block size="small">
      <Box.h1>Subprocessors</Box.h1>
      <Box.p>
        <i>Last updated: 5 July 2024</i>
      </Box.p>

      <p>
        Swarmia engages with the following third parties and affiliates to
        provide its services. Each subprocessor has been carefully evaluated to
        ascertain their compliance with data protection regulations and best
        practices.
      </p>

      <Box.h2>Third party</Box.h2>
      <Stack>
        <SubprocessorTable subprocessors={thirdParties} />
        <Box.span font="medium">
          <i>
            You may choose not to use the functionality provided by
            Subprocessors marked with asterisk. If you want to opt-out from
            using a Subprocessor, please contact{' '}
            <a href="mailto:security@swarmia.com">security@swarmia.com</a>
          </i>
        </Box.span>
      </Stack>
      <Box.h2>Affiliates</Box.h2>
      <SubprocessorTable subprocessors={affiliates} />
    </Block>
  </Page>
)
